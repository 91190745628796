<app-header></app-header>

<!-- <div class="about-section section container">
    <div class="row">
      <div class="col-12 col-lg-12 mb-3 mb-lg-0">
        <p class="display-5 fw-normal">Kidia Oneexpress</p>
        <p style="text-align: justify;">
          Kidia One Express
          We do provide scheduled daily bus transportation from Dar es Salaam to Moshi, Arusha, Dodoma, Tabora, Kaliua, Uvinza, Kasulu, Kigoma and from these region to Dar es Salaam daily. Our booking Bus Offices are in Dar es Salaam, Moshi, Boma Ng'ombe, Kiboriloni, Arusha, Morogoro, Kasuluand Kigoma.
      </div>
    </div>
  </div> -->
<div class="about-section section container">
  <div class="row">
    <div class="col-md-6">
      <img
        class="img-fluid"
        src="../../../assets/transline/Transline-Cruiser-05.jpeg"
        alt="team svg"
      />
    </div>
    <div class="col-md-6">
      <h3>Transliner Cruiser</h3>
      <p>
        At Transliner Cruiser, our mission is to revolutionize the
        transportation industry by leveraging cutting-edge technology to deliver
        exceptional experiences to our clients. With over 60 state-of-the-art
        shuttles at our disposal, we have been providing reliable and efficient
        services since 2023. <br> <br>  Built on our expertise in the passenger and courier
        services sector, we have partnered with Ramsdale Courier to identify and
        overcome industry challenges. Our goal is to offer unparalleled services
        that meet the evolving needs of our clients. Currently, we operate over
        15 routes within Kenya and are committed to expanding our network to
        cover more routes within the country and beyond, including the greater
        East African region. <br> <br> 
        By combining our passion for innovation with a deep
        understanding of the industry, we aim to delight, retain, and defend our
        clients by providing world-class transportation solutions that exceed
        their expectations.
      </p>
    </div>
  </div>
</div>
<app-footer></app-footer>
