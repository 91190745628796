<!-- <footer class="bg-dark text-white">
  <div class="container">
    <footer class="pt-5 pb-3">
      <div class="row">
        <div class="col-12 col-md-3 mb-3">
          <div class="d-flex align-items-end gap-2 mb-3">
            <img src="assets/img/logo.png" height="50px" width="171px" alt="">
          </div>
        </div>
        <div class="col-12 col-md-3 mb-3">
          <h5>Quick Links</h5>
          <ul class="nav flex-column">
            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-light">Home | About us | Services</a></li>
         
          </ul>
        </div>
        <div class="col-12 col-md-3 mb-3">
          <h5>Contact info</h5>
          <ul class="nav flex-column">
            
            <li class="nav-item mb-2">
              <a href="#" class="nav-link p-0 text-light">
                 <i class="fas fa-envelope fa-fw me-2"></i>coast@coastbus.com

              </a>
            </li>
          
          </ul>
        </div>
        <div class="col-12 col-md-3 mb-3">
          <form>
            <h5>Subscribe to our newsletter</h5>
            <div class="d-flex flex-column flex-sm-row w-100 gap-2">
              <label for="newsletter1" class="visually-hidden">Email address</label>
              <input id="newsletter1" type="text" class="form-control" placeholder="Email address">
              <button class="btn btn-primary" type="button">Subscribe</button>
            </div>
          </form>
        </div>
      </div>

      <div class="d-flex flex-column flex-sm-row justify-content-between pt-4 border-top">
        <p>© 2022 Company, Inc. All rights reserved.</p>
        <ul class="list-unstyled d-flex">
          <li class="ms-3">
            <a class="link-light" href="#">
              <i class="fab fa-facebook-f fa-fw"></i>
            </a>
          </li>
          <li class="ms-3">
            <a class="link-light" href="#">
              <i class="fab fa-twitter fa-fw"></i>
            </a>
          </li>
          <li class="ms-3">
            <a class="link-light" href="#">
              <i class="fab fa-linkedin-in fa-fw"></i>
            </a>
          </li>
          <li class="ms-3">
            <a class="link-light" href="#">
              <i class="fab fa-instagram fa-fw"></i>
            </a>
          </li>
        </ul>
      </div>
    </footer>
  </div>
</footer> -->

<div style="background-color: #a30d2c; color: white">
  <div class="container-fluid top">
    <div class="container">
      <div class="row mt-3">
        <p
          style="
            font-weight: bold;
            text-transform: capitalize;
            text-align: center;
          "
        >
          Transliner Cruiser Contacts <br> paybill: 3038861
        </p>
        
        <div class="col col-md-6 col-lg-3">
           <p>Nairobi Office</p>
          <small>Kibera: 0723467467 </small> <br />
          <small>Hilloks: 0728115471 </small> <br />
          <small>Tassia: 0710748692 </small><br />
          <small>Imara: 0708185377 </small><br />
          <small>Utawala: 0729354130 / 0726622509 </small> <br />
          <small>Mathare: 0796817945 </small> <br />
          <small>Bcenter: 072326003 </small> <br />
          <small>Soweto: 0723246553</small> <br />
          <small>Ruiru: 0727429548</small> <br />
        </div>
        <div class="col col-md-6 col-lg-3">
          <small>Homabay: 0792102322 </small> <br />
          <small>Ogembo: 0720264199 </small> <br />
          <small>Magena: 0712992203 </small><br />
          <small>Kenyenya: 0713023900 </small><br />
          <small>Kamenta: 0702947324 </small> <br />
          <small>Mogonga: 0790411973 </small>
        </div>
        <div class="col col-md-6 col-lg-3">
          <small>Nyamache: 0701206508 / 0724698139 </small> <br />
          <small>Katito: 0757882155 </small><br />
          <small>Keroka: 0716270042 </small><br />
          <small>Ekerenyo: 0795541244 </small> <br />
          <small>Kebiringo:0717049493 </small> <br />
          <small>Nyamira: 0727740430 </small> <br />
        </div>
        <div class="col col-md-6 col-lg-3">
          <small>Nyacheki: 0793577799</small> <br />
          <small>Kisii: 0719317152 / 0791673939 </small><br />
          <small>Chepilat: 0799010259 </small><br />
          <small>Oyugis: 0797086976 </small> <br />
          <small>Kemenya: 0711723076 </small><br />
          <small>Ikonge: 0719114371 </small> <br />
        </div>
      </div>
    </div>
  </div>
  <div class="row text-center top p-1" style="width: 100vw">
    <hr />
    <small style="font-size: small">All rights reserved</small>
  </div>
</div>
